import { gql, useQuery } from "@apollo/client";
import { Tarification } from "./types";

const GET_TARIFICATIONS = gql `
    {
        tarifications {
            id
            name
            price
            description
            code
        }
    }
`;

export const useTarificationQuery = () => {
    const { data, error, loading } = useQuery< { tarifications: Tarification[] }, {}>(GET_TARIFICATIONS);

    return { tarifications: data?.tarifications, loading, error };
};