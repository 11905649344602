import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Content } from './types';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../routes';
import { Typography } from '@mui/material';

const ContentList: React.FC<{ contents: Content[] }> = ({ contents }) => {
    const navigate = useNavigate();

    return (
        <List sx={{ width: '100%' }}>
            { contents.map((content, index) => (
                <>
                    <ListItem alignItems="center" onClick={() => navigate(Routes.blog_detail.replace(':uri', content.uri))}>
                        <ListItemAvatar>
                            <Avatar 
                                alt={`Image contenu ${index}`}
                                variant='rounded'
                                src={content.image ? content.image : "https://www.grandlabolshi.com/images/logo.png"} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={content.title}
                            secondary={<Typography variant='overline'> — {content.tags.join(', ')}</Typography>}
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </>
            ))}
        </List>
    );
};

export default ContentList;