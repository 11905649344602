import { useNavigate } from "react-router-dom";
import { Content } from "./types";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { CardActionArea, Grid } from '@mui/material';
import { Routes } from "../../routes";

export const ContentCard: React.FC<{ content: Content }> = ({ content }) => {
    const navigate = useNavigate();

    return (
        <Card sx={{ marginBottom: 2, marginLeft: 1, marginRight: 1, width: '100%' }}>
            <CardActionArea onClick={() => navigate(Routes.blog_detail.replace(':uri', content.uri))}>
                <CardMedia
                    component="img"
                    height={200}
                    image={content.image ? content.image : "https://www.grandlabolshi.com/images/logo.png"}
                    alt="Image content card"
                />
                <CardContent>
                    {content.tags?.map((tag, index) => <Chip variant="outlined" key={index.toString()} label={tag} sx={{ marginRight: '5px' }} />)}
                    <Typography variant="h5" >
                        {content.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const Cards: React.FC<{ contents: Content[] }> = ({ contents }) => {
    return (
        <Grid container alignContent='stretch' alignItems={'stretch'}>
            { contents.map((content, index) => (
                <Grid 
                    item 
                    xs={12}
                    md={6}
                    lg={4}
                    key={index.toString()}
                    component={ContentCard}
                    content={content} />
            ))}
        </Grid>
    )
};

export default Cards;