import React from 'react';
import { Layout, Row, Col, Typography, List, Card, Image, Button, Space } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import image from '../assets/images/image-labo.jpg';
import Performance from '../components/performance';
import Social from '../components/social';
import Testimony from '../components/testimony';
import CTA from '../components/cta';
import { useNavigate } from 'react-router-dom';
import TypewriterComponent from 'typewriter-effect';
import { Routes } from '../routes';

const { Content } = Layout;
const { Title, Text } = Typography;

const Home : React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className='App'>
            <Content style={{ padding: '7px', textAlign: 'left' }}>
                <Card>
                    <Space direction='vertical'>
                        <Row>
                            <Col xs={24} md={12} style={{ padding: '5px'}}>
                                <Title style={{ textAlign: 'center', minHeight: 200, borderColor: 'black', borderWidth: 1 }}>
                                    Bénéficier de l'expertise <br />
                                    <TypewriterComponent
                                        onInit={(typewriter) => {
                                            typewriter
                                                .typeString("des géants de l'analyse médicale")
                                                .pauseFor(1500)
                                                .deleteAll()
                                                .typeString("de la référence au grand katanga")
                                                .pauseFor(1500)
                                                .deleteAll()
                                                .typeString("d'un partenaire de confiance")
                                                .pauseFor(1500)
                                                .deleteAll()
                                                .start();
                                        }}
                                        options={{ loop: true }}
                                    />
                                </Title>
                                <Text type='secondary'>Pourquoi faire ses analyses au grand laboratoire de Lubumbashi ?</Text>
                                <List
                                    dataSource={[
                                        'Tarif extrêmement abordable',
                                        'Fait par des experts du domaine',
                                        'Résultat rapide et disponible en ligne',
                                        'Être averti une fois le résultat disponible'
                                    ]}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <CheckCircleOutlined color='blue' /> {item}
                                        </List.Item>
                                    )}
                                />
                                <br/>
                                <Button shape='round' type='primary' onClick={() => navigate(Routes.tarification)}>Facturation gratuite</Button>{' '}
                                <Button shape='round' type='primary' href='https://www.grandlabolshi.com/demande-test/new'>Test covid</Button>
                                <br/>
                                <br/>
                            </Col>
                            <Col xs={24} md={12}>
                                <Image src={image} preview={false} />
                            </Col>
                        </Row>
                    </Space>
                </Card>
            </Content>
            <Performance />
            <Social />
            <Testimony />
            <CTA />
            <br />
        </div>
    );
};

export default Home;