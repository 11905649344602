import { Button, message, Result, Spin } from "antd";
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { Content } from "./types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import './index.css';
import { Contents } from "./Contents";
import React from "react";
import { useContentQuery } from "./api";

export const ShowContent: React.FC = () => {
    const [content, setContent] = React.useState<Content & { text: string }>();
    const { uri } = useParams();
    const navigate = useNavigate();
    const { query, loading, error } = useContentQuery();

    React.useEffect(() => {
        if(uri) {
            window.scrollTo({ top: 0, behavior: 'smooth'});
            query(uri).then((content) => setContent(content));
        }
    }, [uri]);

    React.useEffect(() => {
        if(content) {
            message.info('Bonne lecture!', 3);
        }
    }, [content]);

    if(error) 
        return (
            <Result
                status={'404'}
                title='Aucun contenu trouvé'
                subTitle={error.message}
                extra={<Button onClick={() => navigate(-1)}>Retour</Button>}  />
        );
    
    return (
            <Box sx={{ padding: 0.5 }}>
                <Spin spinning={loading} tip='Récupération du contenu...'>
                    <Card variant="outlined" sx={{ minHeight: 300 }}>
                        <CardContent>
                            <Typography variant='overline'>{content?.tags.join(', ')}</Typography>
                            {content && (
                                <ReactMarkdown className="content" remarkPlugins={[remarkGfm]}>
                                    {content.text}
                                </ReactMarkdown>
                            )}
                        </CardContent>
                    </Card>
                </Spin>
                <Typography variant='h6' sx={{ textAlign: 'center', margin: 3 }}>Ceci pourrait vous interesser</Typography>
                <Card variant='outlined'>
                    <Contents
                        numberOfItems={6}
                        incrementItems={5}
                        list
                        filter={(contents) => {
                            let items = contents.filter(item => item.id !== content?.id);
                            return items.filter((item) => item.tags.some((tag) => content?.tags.includes(tag)));
                        }}
                    />
                </Card>
            </Box>
    );
}