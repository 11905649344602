import { MailOutlined, PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Row, Col, Card, Layout, Avatar, Button } from "antd";
import img_pdg from '../assets/images/pdg.jpg';
import img_igor from '../assets/images/igor.jpg';
import ReactVisibilitySensor from "react-visibility-sensor";
import Grow from "@mui/material/Grow";
import React from "react";



const Testimony : React.FC = () => {
    const [visible, setVisible] = React.useState({ 1: false, 2: false });
    return (
        <Layout.Content>
            <Row>
                <Col xs={24} md={12}>
                    <ReactVisibilitySensor 
                        partialVisibility='bottom'
                        offset={{ bottom: -70 }}
                        onChange={(isVisible: boolean) => {
                            setVisible({ ...visible, 1: isVisible });
                        }}>
                        <Grow in={visible[1]} timeout={1000}>
                            <Card style={{ margin: '5px' }}>
                                <Avatar size={70} src={img_pdg} style={{ marginBottom: 20 }} />
                                <Card.Meta 
                                    title="Jacques Muzinga, Directeur" 
                                    description={(
                                        <blockquote>
                                            Nous faisons de notre mieux pour offir dans cet ère 
                                            medicale le meilleur service possible à la population congolaise.
                                        </blockquote>
                                    )} />
                            </Card>
                        </Grow>
                    </ReactVisibilitySensor>
                </Col>
                <Col xs={24} md={12}>
                    <ReactVisibilitySensor 
                        partialVisibility='bottom'
                        offset={{ bottom: -70 }}
                        onChange={(isVisible: boolean) => {
                            setVisible({ ...visible, 2: isVisible });
                        }}>
                        <Grow in={visible[2]} timeout={1000}>
                            <Card style={{ margin: '5px' }}>
                                <Avatar size={70} src={img_igor} style={{ marginBottom: 20 }} />
                                    <Card.Meta 
                                        title="Igor Kaswi, Ingénieur système" 
                                        description={(
                                            <>
                                                <blockquote>
                                                    La technologie peut améliorer le rendement attendu et c'est là le 
                                                    défis que nous avons: Améliorer la sécurité médicale en offrant les
                                                    meilleures technologies possibles.
                                                </blockquote>
                                                <Button type="link" href="mailto:kaswikasokotaigor@gmail.com" icon={<MailOutlined />} color='red'>Email</Button>
                                                <Button type="link" href="tel:+243978297229" icon={<PhoneOutlined />}>Téléphone</Button>
                                                <Button type="link" href="whatsapp://send?phone=243978297229" icon={<WhatsAppOutlined />} color='success'>Whatsapp</Button>
                                            </>
                                        )} />
                            </Card>
                        </Grow>
                    </ReactVisibilitySensor>
                </Col>
            </Row>
        </Layout.Content>
    );
};

export default Testimony;