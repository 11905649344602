import Footer from 'rc-footer';
import 'rc-footer/assets/index.css';
import React, { FC } from 'react';
import { GlobalOutlined, HomeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { CoronavirusOutlined } from '@mui/icons-material';

const MyFooter : FC  = () => {
    return (
        <Footer
            columns={[
                {
                    icon: <HomeOutlined />,
                    title: 'Grand Labo',
                    items: [
                        {
                            title: 'Accueil',
                            url: "/landing"
                        },
                        {
                            title: 'Devenir partenaire',
                            url: "/landing/blog/partenariat-avec-le-grand-laboratoire-de-lubumbashi",
                        },
                        {
                            title: 'Contact',
                            url: "/landing/about",
                        },
                    ]
                },
                {
                    icon: <CoronavirusOutlined />,
                    title: 'Test Covid 19',
                    items: [
                        {
                            title: 'Demande de test',
                            url: "https://www.grandlabolshi.com/patient/new"
                        },
                        {
                            title: 'Résultat',
                            url: "https://www.grandlabolshi.com/resultat-test-covid",
                        },
                        {
                            title: 'Demande en cours',
                            url: "https://www.grandlabolshi.com/contact"
                        }
                    ]
                },
                {
                    icon: <GlobalOutlined />,
                    title: 'Sciences',
                    items: [
                        {
                            title: 'Recherche Scientifique',
                            url: "/landing/blog/recherche-scientifique-au-grand-laboratoire-de-lubumbashi"
                        }
                    ]
                },
                {
                    icon: <InfoCircleOutlined />,
                    title: 'Autres informations',
                    items: [
                        {
                            title: 'Formation',
                            url: "/landing/blog/formation-au-grand-laboratoire-de-lubumbashi"
                        },
                        {
                            title: 'Stage',
                            url: "/landing/blog/stage-au-grand-laboratoire-de-lubumbashi"
                        },
                        {
                            title: 'Visite guidée',
                            url: "/landing/blog/visite-guidée-au-sein-du-grand-laboratoire"
                        }
                    ]
                }
            ]}
            bottom="Grand Laboratoire de Lubumbashi - DRC © 2023"
            columnLayout="space-around"
        />
    );
}

export default MyFooter;