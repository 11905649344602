import { Zoom, Box } from "@mui/material";
import { Button, Layout, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import ReactVisibilitySensor from "react-visibility-sensor";
import { Routes } from "../routes";



const CTA : React.FC = () => {
    const navigate = useNavigate();
    return (
        <Layout.Content>
            <ReactVisibilitySensor partialVisibiity='bottom'>
                {({ isVisible} : { isVisible: boolean }) => (
                    <Box>
                        <Typography.Title>Calculez gratuitement une facture</Typography.Title>
                        <Zoom in={isVisible} timeout={1000}>
                            <Button type="primary" size="large" shape="round" onClick={() => navigate(Routes.tarification)}>Essayer</Button>
                        </Zoom>
                    </Box>
                )}
            </ReactVisibilitySensor>
        </Layout.Content>
    );
};

export default CTA;