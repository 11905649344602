import { Grow, Slide, Box, Card } from "@mui/material";
import { Layout, Typography, Image, Avatar } from "antd";
import { ClockCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import React from "react";
import CTA from "../components/cta";
import Testimony from "../components/testimony";
import portes from '../assets/images/portes-batiment.jpg';
import machines from '../assets/images/machines.jpg';


const About: React.FC = () => {
    const [show, setShow] = React.useState(false);
    React.useEffect(() => {
        setTimeout(() => setShow(true), 290);
    }, [])
    return (
        <Layout.Content className="App">
                <Slide in={show} direction='up' timeout={1000} mountOnEnter>
                    <Box sx={{ padding: 2 }}>
                        <Image src={portes} alt='Entrée' preview={false} width={250}/>
                    </Box>
                </Slide>
                <Grow in={show} timeout={2000}>
                    <Box>
                        <Card variant="outlined" sx={{ padding: 1.5, margin: 0.5 }}>
                            <Typography.Title level={2}>Présentation</Typography.Title>
                            <Typography.Paragraph style={{ fontSize: 17 }}>
                                Créé en 1912, le Grand Laboratoire Médical de Lubumbashi autrement appelé laboratoire de santé
                                publique de référence de Lubumbashi intervient dans la surveillance des maladies à potentiel épidémique
                                (Rougeole, Rubéole, Paludisme, VIH, Choléra, Grippe ...). En routine, il réalise des analyses de
                                laboratoire clinique telles que biochimiques (avec dosage des hormones et marqueurs tumoraux), les
                                cultures bactériennes (hémoculture), des examens parasitologiques, des tests sérologiques, ainsi que le
                                bilan prénuptial. Par rapport à la riposte contre la covid-19, le laboratoire réalise des tests de confirmation
                                de cas suspects ainsi que le dépistage des voyageurs.
                            </Typography.Paragraph>
                            <Typography.Paragraph style={{ fontSize: 17 }}>
                                Après sa réhabilitation le Grand Laboratoire Médical de Lubumbashi est devenu un établissement
                                moderne qui utilise des technologies de pointe pour analyser des échantillons biologiques tels que le sang,
                                les urines, les selles et autres liquides biologiques. Il offre une variété de tests de laboratoire, notamment
                                des tests de diagnostic, de dépistage et de suivi pour diverses maladies et troubles de la santé. Il utilise des
                                outils de laboratoire automatisés pour améliorer la qualité, la précision et la rapidité dans le rendu des
                                résultats. 
                                Avec comme devise <span style={{ fontWeight: 'bold' }}>"ensemble pour un laboratoire meilleur"</span>, le personnel de ce laboratoire
                                œuvre pour plus de qualité dans les services rendus à la communauté.
                            </Typography.Paragraph>
                            <Image src={machines} alt='Machines' preview={false} width={250}/>
                        </Card>
                        <Card variant="outlined" sx={{ padding: 1.5, margin: 0.5 }}>
                            <Avatar icon={<ClockCircleOutlined />} size={70} style={{ backgroundColor: '#33CCFF', marginTop: 15 }} />
                            <Typography.Title level={2}>Horaire de service</Typography.Title>
                            <Typography.Paragraph style={{ fontSize: 17 }}>
                                Vous recherchez un laboratoire médical fiable et efficace pour vos analyses de laboratoire ? Nous avons ce qu’il vous faut !!!!! Notre laboratoire est ouvert :  
                                <br/>
                                <ul>
                                    <li>Du lundi au vendredi  de : 7h30 à 16h30</li>
                                    <li>Samedi  de 7h30 à 14h30</li>
                                    <li>Dimanche de 7h30 à 12h30</li>
                                </ul>
                                <br />
                                Nous offrons une variété de services de laboratoire de haute qualité pour répondre à tous vos besoins d'analyses. 
                                Notre personnel qualifié et expérimenté est là pour vous assisté 
                                tout au long de votre visite et s’assurer que vos résultats sont 
                                obtenus rapidement et de manière précise. 
                                Nous utilisons des équipements de pointe pour garantir 
                                des résultats fiables et précis. <br />
                                Ne perdez plus de temps à chercher un laboratoire de qualité, rendez-vous chez nous aujourd’hui   et laisser-nous prendre soin de votre santé. 
                            </Typography.Paragraph>
                        </Card>
                        <Card variant="outlined" sx={{ padding: 1.5, margin: 0.5 }}>
                            <Avatar icon={<InfoCircleOutlined />} size={70} style={{ backgroundColor: '#33CCFF', marginTop: 15 }} />
                            <Typography.Title level={2}>Contact</Typography.Title>
                            <Typography.Paragraph style={{ fontSize: 17 }}>
                                Le Grand Laboratoire Médical de Lubumbashi se situe au 491, 
                                Avenue LIKASI coin KAPENDA dans la Commune de LUBUMBASHI en REPUBLIQUE DEMOCRATIQUE DU CONGO.
                                <br />
                                Pour prendre rendez-vous, vous pouvez nous contactez au 
                                numéro de téléphone +243817108898, ou envoyez un mail au <a href="mailto:laboratoirepoolkatanga@gmail.com"> laboratoirepoolkatanga@gmail.com</a>, 
                                pour plus d’informations visitez notre site <a href="https://www.grandlabolshi.com">www.grandlabolshi.com </a> 
                                et aussi notre page Facebook : Grand Laboratoire Médical de Lubumbashi. <br />
                                Le Grand Laboratoire Médical de Lubumbashi propose une large 
                                gamme de tests médicaux pour les particuliers et les professionnels de santé. <br />
                                N’hésitez pas à nous contacter pour toute demande d’informations complémentaires.
                            </Typography.Paragraph>
                        </Card>
                    </Box>
                </Grow>
            <Testimony />
            <CTA />
            <br />
        </Layout.Content>
    );
};

export default About;