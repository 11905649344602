import { Box, Button } from "@mui/material"
import { Result, Spin } from "antd";
import React from "react";
import { useContentsQuery } from "./api"
import Cards from "./Cards";
import ContentList from "./List";
import { Content } from "./types";

export const Contents: React.FC<{
    numberOfItems: number,
    incrementItems: number,
    filter?: (contents: Content[]) => Content[],
    list?: boolean
}> = ({
    numberOfItems,
    incrementItems,
    filter,
    list
}) => {
    const { contents, loading, error } =  useContentsQuery();
    const [itemsNumber, setItemNumber] = React.useState(numberOfItems - 1);

    const more = () => {
        if(contents && itemsNumber < contents.length)
            setItemNumber(itemsNumber + incrementItems);
    };

    if(error)
        return (
            <Result status={'404'} title='Aucun contenu trouvé' />
        )

    return (
        <Spin spinning={loading} tip='Veuillez patienter...'>
            <Box sx={{ minHeight: 300 }}>
                {list 
                    ? (<ContentList 
                        contents={contents 
                            ? filter 
                                ? filter(contents).slice(0, itemsNumber) 
                                : contents.slice(0, itemsNumber) 
                            : []} 
                    />)
                    : (<Cards 
                        contents={contents 
                            ? filter 
                                ? filter(contents).slice(0, itemsNumber) 
                                : contents.slice(0, itemsNumber) 
                            : []} 
                    />)}
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                {(contents && ( filter ? filter(contents).length : contents.length) > itemsNumber) && (<Button fullWidth onClick={more}>Plus...</Button>) }
            </Box>
        </Spin>
    );
}