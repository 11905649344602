import Layout from './layout';
import { createBrowserRouter } from "react-router-dom";
import Home from './pages/Home';
import ContentList from './pages/content';
import { ShowContent } from './pages/content/show';
import About from './pages/about';
import Tarif from './pages/tarif';

export const Routes = {
    basename: '/landing',
    home: '/',
    // covid: '/covid',
    blog: '/blog',
    blog_detail: '/blog/:uri',
    result: '/result/:code',
    about: '/about',
    tarification: '/tarification'
};

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        // errorElement: <ErrorPage />,
        children: [
            {
                path: '',
                element: <Home />
            },
            {
                path: 'blog',
                element: <ContentList />,
            },
            {
                path: 'blog/:uri',
                element: <ShowContent />
            },
            {
                path: 'about',
                element: <About />
            },
            {
                path: 'tarification',
                element: <Tarif />
            }
        ]
    }
], { basename: '/landing'});

export default router;