import { Box, Typography } from "@mui/material";
import { Contents } from "./Contents"

export default function index() {

    return (
        <Box>
            <Typography variant="h1" sx={{ textAlign: 'center', fontSize: 30,  margin: 1 }}>Blog du grand laboratoire de Lubumbashi</Typography>
            <Typography variant="body1" sx={{ textAlign: 'center', marginBottom: 5 }}>
                Toutes les informations à portée de main.
            </Typography>
            <Contents numberOfItems={10} incrementItems={10} />
        </Box>
    );
}