import { DeleteOutlined } from "@ant-design/icons";
import { Add } from "@mui/icons-material";
import { Button as MuiButton } from '@mui/material';
import { Button, Card, Col, Input, Layout, List, Row, Statistic, Affix, Typography, Space, message, Tooltip, Tag, Image } from "antd";
import React, { useState } from "react";
import logo from '../../assets/images/logo.png';
import { useTarificationQuery } from "./api";
import { InvoiceItem, Tarification } from './types';



const Tarif : React.FC = () => {
    const [selected, setSelected] = useState<Map<string|number, InvoiceItem>>(new Map());
    const [total, setTotal] = useState<number>(0);
    const { tarifications, loading } = useTarificationQuery();
    const [search, setSearch] = React.useState<string>('');
    const [itemsNumber, setItemsNumber] = React.useState(10);

    const [data, setData] = useState<Tarification[]>();
    
    const handleClick = (item: Tarification) => {
        if (selected?.has(item.id)) {
            let current = selected.get(item.id);
            if(current){
                selected.set(item.id, { ...current, count: current.count + 1 })
            }
        } else {
            selected?.set(item.id, { tarification: item, count: 1 });
        }

        let total = 0;
        selected?.forEach((invoiceItem) => {
            total += invoiceItem.tarification.price * invoiceItem.count;
        });

        setTotal(total);
        setSelected(new Map(selected));
    };

    const handleReset = () => {
        selected.clear()
        setSelected(selected);
        setData(tarifications);
        setTotal(0);
        setSearch('');
        message.info('Réinitialisation effectuée !');
    };

    const count = (id: number|string): number => {
        let count = selected.get(id)?.count;
        return count ? count : 0;
    };

    const sort = (a: Tarification, b: Tarification) => {
        if(a.name < b.name) return -1;
        if(a.name > b.name) return 1;

        return 0;
    };

    const loadMore = (data && itemsNumber < data.length) && <MuiButton fullWidth sx={{ margin: 0.5 }} onClick={() => setItemsNumber(itemsNumber + 10)}>Afficher plus</MuiButton>;

    React.useEffect(() => {
        if(tarifications)
            setData(tarifications?.filter((tarification) => tarification.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())));
    }, [tarifications, search]);

    return (
        <Layout.Content className="App">
            <Affix offsetTop={2}>
                <Card>
                    {/* <Button icon={<PrinterOutlined />} /> */}
                    <Row>
                        <Col xs={24}>
                            <Statistic title={'Total'} value={total} suffix={'FC'} />
                        </Col>
                    </Row>
                    <Input.Search placeholder="Rechercher..." size="large" onChange={({ target }) => setSearch(target.value)} onSearch={setSearch} />
                    <Row style={{ marginTop: 10, alignItems: 'center' }}>
                        <Col xs={12}>
                            <Typography.Text type='secondary' style={{ marginTop: 10 }}>{data?.length} resultat(s)</Typography.Text>
                        </Col>
                        <Col xs={12} style={{ alignItems: 'right'}}>
                            <Space>
                                {/* <Tooltip title={'Obtenir la facture'}>
                                    <Button icon={<PrinterOutlined />} disabled={selected.size === 0} />
                                </Tooltip> */}
                                <Tooltip title={'Réinitialiser'}>
                                    <Button icon={<DeleteOutlined />} onClick={handleReset} />
                                </Tooltip>
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Affix>
            <List
                dataSource={data?.sort(sort).slice(0, itemsNumber)}
                itemLayout='horizontal'
                loading={loading}
                loadMore={loadMore}
                renderItem={(tarification) => {
                    let counter = count(tarification.id);
                    return (
                        <Card style={{ margin: 5 }}>
                            <List.Item actions={[<Button onClick={() => handleClick(tarification)} icon={<Add />} />]}>
                                <List.Item.Meta
                                    avatar={<Image src={logo} width={40} />}
                                    title={tarification.name}
                                    description={tarification.price + ' FC'} />
                                <Tag color={counter === 0 ? "default" : "processing"} >{counter}</Tag>
                            </List.Item>
                        </Card>
                    );
                }} />
        </Layout.Content>
    );
};

export default Tarif;