import React, { FC } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './routes';

const App: FC = () => (
    <div>
        <RouterProvider router={router} />
    </div>
);

export default App;