import React, { FC } from 'react';
import { Row, Col, Typography, Card, Image, Layout, Space, Statistic } from 'antd';
import img_statistic from '../assets/images/statistic.jpeg';
import img_medical_service from '../assets/images/service medical.jpeg';
import img_covid from '../assets/images/covid.jpeg';
import img_analyse from '../assets/images/analyse-2.jpg';
import { FileDoneOutlined } from '@ant-design/icons';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { Grid, Grow } from '@mui/material';


const Perofmance: FC = () => {
    const [show, setShow] = React.useState({
        1: false,
        2: false,
        3: false,
        4: false
    });

    return (
        <Layout.Content style={{ padding: '3px', textAlign: 'left' }}>
            <Space direction='vertical'>
                <Typography.Title level={2} style={{ textAlign: 'center' }}>Nous sommes au centre du Grand Katanga</Typography.Title>
                <Grid container style={{ alignItems: 'stretch' }}>
                    <Grid item xs={6} md={4}>
                        <Card style={{ textAlign: 'center', margin: '5px' }}>
                            <Statistic title="Divisions provinciales de la santé" value={4} prefix={<FileDoneOutlined />} />
                        </Card>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Card style={{ textAlign: 'center', margin: '5px' }}>
                            <Statistic title="Zones de santé servies" value={68} prefix={<FileDoneOutlined />} />
                        </Card>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Card style={{ textAlign: 'center', margin: '5px' }}>
                            <Statistic title="Aires de santé" value={1258} prefix={<FileDoneOutlined />} />
                        </Card>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Card style={{ textAlign: 'center', margin: '5px' }}>
                            <Statistic title="Tests covid réalisés" value={500000} suffix={'+'} />
                        </Card>
                    </Grid>
                </Grid>
                <Typography.Title level={2} style={{ textAlign: 'center' }}>Plusieurs services offerts à tout le Grand Katanga</Typography.Title>
                <Card>
                    <Row>
                        <Col xs={24} md={12} lg={6} style={{ padding: '5px' }}>
                            <ReactVisibilitySensor
                                partialVisibility='bottom'
                                offset={{ bottom: -70 }}
                                onChange={(isVisible: boolean) => setShow({ ...show, 1: true })}
                            >
                                <Grow in={show[1]} timeout={1000}>
                                    <Card hoverable cover={<Image src={img_statistic} preview={false} style={{ borderRadius: '5px 5px 0 0' }} />} style={{ borderRadius: '5px' }}>
                                        <Card.Meta 
                                            title="Surveillance des maladies"
                                            description="Nous nous occupons du grand Katanga en qui 
                                            concerne la surveillance des maladies épidemiques" />
                                    </Card>
                                </Grow>
                            </ReactVisibilitySensor>
                        </Col>
                        <Col xs={24} md={12} lg={6} style={{ padding: '5px' }}>
                            <ReactVisibilitySensor
                                partialVisibility='bottom'
                                offset={{ bottom: -70 }}
                                onChange={() => setShow({ ...show, 2: true })}
                            >
                                <Grow in={show[2]} timeout={1000}>
                                    <Card hoverable cover={<Image src={img_medical_service} preview={false} style={{ borderRadius: '5px 5px 0 0' }} />} style={{ borderRadius: '5px' }}>
                                        <Card.Meta 
                                            title="Plusieurs services"
                                            description="Le grand laboratoire de Lubumbashi organise en son sein
                                                plusieurs services de qualité..." />
                                    </Card>
                                </Grow>
                            </ReactVisibilitySensor>
                        </Col>
                        <Col xs={24} md={12} lg={6} style={{ padding: '5px' }}>

                            <ReactVisibilitySensor
                                partialVisibility='bottom'
                                offset={{ bottom: -70 }}
                                onChange={() => setShow({ ...show, 3: true })}
                            >
                                <Grow in={show[3]} timeout={1000}>
                                    <Card hoverable cover={<Image src={img_analyse} preview={false} style={{ borderRadius: '5px 5px 0 0' }} />} style={{ borderRadius: '5px' }}>
                                        <Card.Meta 
                                            title="Plusieurs tests et analyses"
                                            description="Nous réalisons des analyses biochimiques (avec dosage des hormones), bactériologiques
                                            (analyses), parasitologiques, serologiques, ainsi que le bilan prénuptial." />
                                    </Card>
                                </Grow>
                            </ReactVisibilitySensor>
                        </Col>
                        <Col xs={24} md={12} lg={6} style={{ padding: '5px' }}>
                            <ReactVisibilitySensor
                                partialVisibility='bottom'
                                offset={{ bottom: -70 }}
                                onChange={(isVisible: boolean) => setShow({ ...show, 4: isVisible })}
                            >
                                <Grow in={show[4]} timeout={1000}>
                                    <Card hoverable cover={<Image src={img_covid} preview={false} style={{ borderRadius: '5px 5px 0 0' }} />} style={{ borderRadius: '5px' }}>
                                        <Card.Meta 
                                            title="Lutte contre la COVID-19"
                                            description="Nous assurons avec fièrté la sécurité du peuple katangais
                                            en detectant les cas des maladies et fournissons une prise en charge adaptée." />
                                    </Card> 
                                </Grow>
                            </ReactVisibilitySensor>
                        </Col>
                    </Row>
                </Card>
            </Space>
        </Layout.Content>
    );
};

export default Perofmance;