import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Collection } from '../../api/types';
import { Content } from './types';

const GET_CONTENTS = gql `
    {
        redactionContents {
            edges {
                node {
                    _id
                    id
                    uri
                    title
                    tags
                    image
                }
            }
        }
    }
`;

const GET_CONTENT_BY_URI = gql `
    query getContent($uri: String!){
        redactionContents(uri: $uri, first: 1) {
            edges {
                node {
                    id
                    _id
                    uri
                    title
                    tags
                    text
                }
            }
        }
    }
`;

export const useContentsQuery = () => {
    const { data, loading, error } = useQuery<{ redactionContents: Collection<Content>}>(GET_CONTENTS);
    const contents = data?.redactionContents.edges.map(({ node }) => node); 
    
    return { contents, loading, error };
}

export const useContentQuery = () => {
    const [getContent, { loading, error }] = useLazyQuery<{ redactionContents: Collection<Content & { text: string }> }, { uri: string }>(GET_CONTENT_BY_URI);
    
    const query = async (uri: string) => {
        const response = await getContent({ variables: { uri }});

        const array = response.data?.redactionContents.edges.map(({ node }) => node);

        return array?.shift();
    }
    
    return { query, loading, error };
}
