import React, { FC } from 'react';
import { Typography, Image, Layout } from 'antd';
import inrb from '../assets/images/inrb.jpg';
import oms from '../assets/images/oms.jpg';
import sfrontiere from '../assets/images/medecin-sans-frontiere.png';
import nationalhealth from '../assets/images/logo_mini.png';
import cmc from '../assets/images/cmc.png';
import cmd from '../assets/images/cmd.png';
import cdc from '../assets/images/cdc.png';
import cap from '../assets/images/cap.jpg';
import epicentre from '../assets/images/epicentre.png';
import ghss from '../assets/images/ghss.jpg';
import hj from '../assets/images/hj.png';
import rti from '../assets/images/rti.jpg';
import g from '../assets/images/g.jpg';
import sanru from '../assets/images/sanru.png';
import koica from '../assets/images/koica.png';

const Social : FC = () => {
    const HEIGHT = 90;
    const PADDING = 5;
    const BOTTOM = 10;

    return (
        <Layout.Content style={{ padding: '7px', textAlign: 'center' }}>
            <Typography.Title level={2} style={{ textAlign: 'center' }}>Ils nous font confiance.</Typography.Title>
            <Image.PreviewGroup>
                <Image src={inrb} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={sfrontiere} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={nationalhealth} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={cmc} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={cmd} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={cdc} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={cap} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={oms} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={epicentre} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={ghss} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={hj} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={rti} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={g} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={koica} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
                <Image src={sanru} height={HEIGHT} style={{ padding: PADDING, paddingBottom: BOTTOM }} />
            </Image.PreviewGroup>
        </Layout.Content>
    );
};

export default Social;