import { PaymentsOutlined, CoronavirusOutlined, HomeOutlined, InfoOutlined, MenuOutlined, NewspaperOutlined, SettingsOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Col, Layout, Menu, Row, Avatar, Modal } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { Routes } from '../routes';

const MyHeader: React.FC = () => {
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = React.useState(false);
    const handleToggle = () => {
        setOpenMenu(!openMenu);
    };

    return (
        <>
            <Layout.Header style={{ backgroundColor: 'white', boxShadow: '10px' }}>
                <Row>
                    <Col flex={'none'}><Avatar src={logo}/></Col>
                    <Col flex={'auto'} style={{ justifyContent: 'right', textAlign: 'right' }}>
                        <IconButton onClick={handleToggle}><MenuOutlined /></IconButton>
                    </Col>
                </Row>
            </Layout.Header>
            <Modal 
                title='Navigation'
                open={openMenu}
                style={{ textAlign: 'center' }}
                bodyStyle={{ justifyContent: 'center', textAlign: 'center', fontSize: 30 }}
                footer={null}
                closable={true}
                onCancel={handleToggle}>
                <Menu theme='light' mode='vertical' items={[
                        {
                            key: '0',
                            label: 'Accueil',
                            onClick: () => {
                                handleToggle();
                                navigate(Routes.home);
                            },
                            icon: <HomeOutlined />
                        },
                        { 
                            key: '1',
                            label: 'Covid',
                            onClick: () => {
                                handleToggle();
                                window.open('https://www.grandlabolshi.com/demande-test/new', '_blank')
                            },
                            icon: <CoronavirusOutlined />
                        },
                        {
                            key: '2',
                            label: 'Actualités',
                            onClick: () => {
                                handleToggle();
                                navigate(Routes.blog);
                            },
                            icon: <NewspaperOutlined />
                        },
                        {
                            key: '3',
                            label: 'Services',
                            onClick: () => {
                                window.open('/landing/blog/services-du-grand-laboratoire-de-lubumbashi', '_self')
                            },
                            icon: <SettingsOutlined />
                        },
                        {
                            key: '4',
                            label: 'Tarification',
                            onClick: () => {
                                handleToggle();
                                navigate(Routes.tarification);
                            },
                            icon: <PaymentsOutlined />
                        },
                        { 
                            key: '5', 
                            label: 'À propos', 
                            onClick: () => {
                                handleToggle();
                                navigate(Routes.about);
                            },
                            icon: <InfoOutlined />
                        }
                    ]} />
            </Modal>
        </>
    );
}

export default MyHeader;