import { Layout, Button, FloatButton } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import MyFooter from './footer';
import MyHeader from './header';
import { ToTopOutlined } from '@ant-design/icons';

const App: React.FC = () => {

    return (
        <Layout>
            {/* <Affix offsetTop={5}>
                <MyHeader handlePress={() => {}} />
            </Affix> */}
            <MyHeader />
            <Outlet />
            <MyFooter />
            <FloatButton.BackTop><Button type="primary" icon={<ToTopOutlined />} /></FloatButton.BackTop>
        </Layout>
    );
};

export default App;